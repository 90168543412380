import React, { useState } from 'react';
import LoginFormLayout from './LoginFormlayout';
import { login } from './Service/LoginApiService';
import { useNavigate } from 'react-router-dom'; 

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Handle login logic here
        console.log('Email:', email);
        console.log('Password:', password);

        try {
            const userData = await login(email, password);
            console.log('Login successful:', userData);

            if(userData.isAdmin===true){
                console.log('admin login:');
                navigate('/admin-dashboard');
            }else{
                console.log('user login:');
                navigate('/user-dashboard');
            }
            // Handle successful login (e.g., redirect to dashboard)
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    return (
        <LoginFormLayout
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleSubmit={handleSubmit}
        />
    );
};

export default LoginForm;
