import React from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Avatar,
} from "@mui/material";

const Home = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "white",
        p: 3,
      }}
    >
      <Container>
        {/* Hero Section */}
        <Box sx={{ mb: 4, textAlign: "center" }}>
          <Typography
            variant="h2"
            component="h1"
            sx={{ fontWeight: "bold", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
          >
            Welcome to the Online Store
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{ mt: 2, textShadow: "1px 1px 3px rgba(0, 0, 0, 0.4)" }}
          >
            Explore our exclusive collection of products and find the best deals!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              mt: 4,
              px: 4,
              py: 2,
              backgroundColor: "#ff4081",
              ":hover": { backgroundColor: "#ff79a6" },
            }}
          >
            Shop Now
          </Button>
        </Box>

        {/* About Us Section */}
        <Box sx={{ mt: 8, textAlign: "center" }}>
          <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
            About Us
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            We are a dedicated online store providing the best quality products at affordable
            prices. Our goal is to make shopping easy, enjoyable, and accessible for everyone.
          </Typography>
          <Button variant="outlined" color="inherit" size="large">
            Learn More
          </Button>
        </Box>

        {/* Featured Products Section */}
        <Box sx={{ mt: 8 }}>
          <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
            Featured Products
          </Typography>
          <Grid container spacing={4}>
            {/* Example Product Card */}
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 345, backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
                <CardMedia
                  component="img"
                  height="200"
                  image="https://source.unsplash.com/featured/?product"
                  alt="Product Image"
                />
                <CardContent>
                  <Typography variant="h6" component="div">
                    Product Name
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </Typography>
                  <Button variant="contained" color="secondary" sx={{ mt: 2 }} fullWidth>
                    View Product
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            {/* Add more product cards as needed */}
          </Grid>
        </Box>

        {/* Customer Reviews Section */}
        <Box sx={{ mt: 8, textAlign: "center" }}>
          <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
            Customer Reviews
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 345, backgroundColor: "rgba(255, 255, 255, 0.8)", p: 2 }}>
                <CardContent>
                  <Typography variant="h6" component="div">
                    Jane Doe
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {
                      "Amazing service and top-quality products! I am extremely satisfied with my shopping experience."
                    }
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* Add more review cards as needed */}
          </Grid>
        </Box>

        {/* Footer Section */}
        <Box sx={{ mt: 8, py: 4, backgroundColor: "rgba(0, 0, 0, 0.7)", textAlign: "center" }}>
          <Typography variant="body1" color="white">
            &copy; {new Date().getFullYear()} Online Store. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
