
import axios from 'axios';
import API_BASE_URL from '../../../config/apiConfig';

export const login = async (identity, password) => {
    console.log('api email', identity);
    console.log('api password', password);
    
    try {
        // Encode data as form URL encoded
        const params = new URLSearchParams();
        params.append('identity', identity);
        params.append('password', password);
        
        const response = await axios.post(`${API_BASE_URL}auth/Auth/login`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error during login:', error.response ? error.response.data : error.message);
        throw error;
    }
}