import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const Navbar = ({ isLoggedIn, handleLogout }) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          My Online Store
        </Typography>
        <Button component={Link} to="/" color="inherit">
          Home
        </Button>
        {!isLoggedIn ? (
          <Button component={Link} to="/login" color="inherit">
            Login
          </Button>
        ) : (
          <Button onClick={handleLogout} color="inherit">
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

// PropTypes validation
Navbar.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired, // Ensure isLoggedIn is a required boolean
  handleLogout: PropTypes.func.isRequired, // Ensure handleLogout is a required function
};

export default Navbar;
